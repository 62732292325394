
import { elementDelete, elementGet, elementPost, filteredGetBuilder } from './response';

export interface AddressDto {
  id: number,
  type: AddressType | number,
  contact_id: number,
  name: string,
  address: string,
  zip_code: string,
  city: string,
  state: string,
  country_id: number,
  status: 0 | 1 | boolean,
  info?: string | null,
  country_name: string,
  country_name_local: string
}

export enum AddressType {
  default = 1,
  office = 2,
  home = 4,
  headquarters = 8,
  shipping = 16,
}

export const list = filteredGetBuilder<AddressDto>(`backend/addresses`);
export const add = (data: Partial<AddressDto>) => elementPost<{ id: number }>(`backend/addresses`, { json: data });
export const update = (id: number | string, data: Partial<AddressDto>) => elementPost<{ id: number }>(`backend/addresses/${id}`, { json: data });
export const get = async (id: number | string) => elementGet<AddressDto>(`backend/addresses/${id}`);
export const remove = async (id: number | string) => elementDelete<never>(`backend/addresses/${id}`);

export default {
  list,
  add,
  update,
  get,
  remove,
};