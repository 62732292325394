import { createHead } from '@unhead/vue';
import { UnheadPlugin } from '@unhead/vue/vue2';
import axios from "axios";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { throttle } from "lodash";
import { PiniaVuePlugin, createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { io } from "socket.io-client";
import Vue from "vue";
import App from "./App.vue";
import "./assets/styles/app.scss";
import PdgDataIdDirective from "./directives/PdgDataIdDirective";
import "./plugins/axios";
import { i18n } from "./plugins/i18n";
import RulesPlugin from './plugins/rules';
import vuetify from "./plugins/vuetify";
import { router } from "./router";
import { useAuthStore } from './store/auth';
import { ConnectedUser, useUsersStore } from './store/users';

dayjs.extend(dayOfYear)
dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(isBetween);
dayjs.locale('fr');

axios.defaults.baseURL = window.config.VUE_APP_PDG_API_BASE_URL ?? process.env.VUE_APP_PDG_API_BASE_URL;

Vue.config.productionTip = true;
// Vue.config.devtools = true;

// Vue.use(
//   VueGtag,
//   {
//     config: { id: process.env.VUE_APP_GTAG_UA_ID, params: { send_page_view: false } },
//     includes: [
//       { id: process.env.VUE_APP_GTAG_GA4_ID, params: { send_page_view: false } },
//     ],
//     pageTrackerScreenviewEnabled: false
//   }
// );

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const head = createHead();
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
Vue.use(UnheadPlugin);

Vue.use(RulesPlugin, {});

Vue.directive('pdg-data-id', PdgDataIdDirective);

new Vue({
  router,
  vuetify,
  i18n,
  pinia,
  render: (h) => h(App),
  // @ts-expect-error : Non géré
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  unhead: head,
  mounted: () => {
    if (process.env.NODE_ENV === 'production') {
      const socket = io();
      socket.on("connect", () => {
        console.log(`Socket.io id: ${socket.id}`);
      });

      socket.on('user-data', function (msg: ConnectedUser) {
        const { update } = useUsersStore();
        update(msg);
      });

      window.addEventListener('mousemove', throttle<(this: Window, ev: MouseEvent) => any>(e => {
        const { user } = useAuthStore();
        if (user) {
          const dataMatch = router.currentRoute.fullPath.match(/(\w+)\/(\d+)(\/update){0,1}$/);
          socket.emit('user-data', {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            username: user.username,
            logo: user.logo,
            date: new Date().toISOString(),
            title: document.title,
            location: router.currentRoute.fullPath,
            dataType: dataMatch?.[1],
            dataId: dataMatch?.[2],
            dataUpdate: dataMatch && dataMatch[3]?.length > 0,
            x: Math.round(e.x / document.body.clientWidth * 100),
            y: Math.round(e.y / document.body.clientHeight * 100),
          } as ConnectedUser);
        }
      }, 200));
    }
  },
}).$mount("#app");

// make vuetify dialogs movable
// https://github.com/vuetifyjs/vuetify/issues/4058
(function () {
  const d = {} as any;
  document.addEventListener("mousedown", e => {
    const closestDialog = (e.target as HTMLElement).closest(".v-dialog.v-dialog--active");
    if (e.button === 0 && closestDialog != null && (e.target as HTMLElement).classList.contains("v-toolbar__content")) { // element which can be used to move element
      d.el = closestDialog; // element which should be moved
      d.mouseStartX = e.clientX;
      d.mouseStartY = e.clientY;
      d.elStartX = d.el.getBoundingClientRect().left;
      d.elStartY = d.el.getBoundingClientRect().top;
      d.el.style.position = "fixed";
      d.el.style.margin = 0;
      d.oldTransition = d.el.style.transition;
      d.el.style.transition = "none"
    }
  });
  document.addEventListener("mousemove", e => {
    if (d.el === undefined) return;
    d.el.style.left = Math.min(
      Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
      window.innerWidth - d.el.getBoundingClientRect().width
    ) + "px";
    d.el.style.top = Math.min(
      Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
      window.innerHeight - d.el.getBoundingClientRect().height
    ) + "px";
  });
  document.addEventListener("mouseup", () => {
    if (d.el === undefined) return;
    d.el.style.transition = d.oldTransition;
    d.el = undefined
  });
  setInterval(() => { // prevent out of bounds
    const dialog = document.querySelector(".v-dialog.v-dialog--active") as HTMLElement;
    if (dialog === null) return;
    dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
    dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
  }, 100);
})();
