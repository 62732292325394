
import { apiHttp } from '../api-http';
import { elementGet, filteredGetBuilder } from './response';

export interface UnitDto {
  id: number;
  symbol: string;
  name: string | null;
  coef: number | null;
  description: string | null;
}

export const list = filteredGetBuilder<UnitDto>(`backend/units`);
export const get = (id: number | string) => elementGet<UnitDto>(`backend/units/${id}`);
export const remove = (id: number | string) => apiHttp.delete(`backend/units/${id}`);

export default {
  list,
  get,
  remove,
}
