import { VueConstructor } from "vue";
import { validateNumber, validateNumberPositive } from "./helper";

interface RulesPluginOptions {
}

const RulesPlugin = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  install(Vue: VueConstructor, _options?: RulesPluginOptions) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    Vue.prototype.$rules = {
      required: (v: string) => !!v || "Champ obligatoire",
      positiveNumber: (v: string) => !!v || validateNumberPositive(v) || "Nombre positif uniquement",
      number: (v: string) => !!v || validateNumber(v) || "Chiffres uniquement",
    }
  }
};

export default RulesPlugin;