import { PageStateOptions } from "@/store/page-state";
import { apiHttp } from "../api-http";
import { filteredGetBuilder } from "./response";

export interface ContactOrgDto {
    id: number,
    contact_id: number,
    org_id: number,
    job_id: number,
    status: 1 | 0 | boolean,
    type: number,
    name: string | null,
    job: string | null,
    phones: string | null, // "[{\"id\":128,\"tel\":\"+33 X XX XX XX XX\",\"type\":3}]",
    emails: string | null //"[{\"id\":20251,\"label\":\"fabrice.constant@prix-de-gros.com\",\"type\":8}]"
}

export const list = filteredGetBuilder<ContactOrgDto>(`backend/contactorgs`);

export const remove = (id: number | string) => apiHttp.delete(`backend/contactorgs/${id}`);

export const listForOrg = (orgId: number, options: PageStateOptions = { itemsPerPage: 10, page: 1, sortBy: ['name'], sortDesc: [false] }) => {
  return list({ org_id: orgId }, options);
}

export default {
  list,
  remove,
  listForOrg,
};