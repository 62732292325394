import { ShippingTypeEnum } from "@/types/shipping";
import { elementGet, filteredGetBuilder } from "./response";


export enum CartItemStatus {
  notAvailable = 0,
  available = 1,
  shippingOnRequest = 2,
  productOnRequest = 3,
}

export enum CartCouponCodeStatus {
  applied = 1,
  expired = 2,
  notCompliant = 4,
  doesNotExist = 8,
  maxUsageReached = 16,
}

export interface CartDto {
  id: number;
  quote_id: number | null;
  quote_uuid: string | null;
  contact_id: number | null;
  date_updated: string; // date
  from_address_id: number | null;
  shipping_country_code: string | null;
  shipping_address_id: number | null;
  shipping_info: string | null;
  shipping_city: string | null;
  shipping_zip_code: string | null;
  total_product: number | null;
  total_shipping: number | null;
  total: number | null;
  contact_name: string | null;
  total_vat: { [key: number]: number };
  total_inc_vat: number | null;
  total_ex_vat: number | null;
  total_eco_part: number | null;
  total_shipping_ex_vat: number | null;
  total_products_ex_vat: number | null;
  total_shipping_inc_vat: number | null;
  total_products_inc_vat: number | null;
  total_shipping_purchase: number | null;
  shipping_type: ShippingTypeEnum;
  include_sor: boolean;
  not_ready?: boolean | null;
  coupon_code: string | null;
  coupon_status: CartCouponCodeStatus;
  // Propre aux devis
  order_id?: number | null;
  shipping_zip_code_fixed?: boolean | null;
  quote_expiry_date: string | null;
  delivery_time: string | null;
  contact_username?: string | null;
}

export interface CartItemDto {
  id: number;
  image: string | null;
  item_id: number;
  name: string;
  slug: string;
  reference: string;
  reference_slug: string;
  packaging: string | null;
  // price: number | null;
  price_sell?: number | null;
  price_buy?: number | null;
  price_supplier?: number | null;
  quantity: number;
  status: CartItemStatus, // TODO: enum
  delivery_time: string | null;
  // total: number;
  brand_name: string;
  packaging_quantity: number | null;
  packaging_unit: string | null; // TODO : enum
  tailormade: number | boolean | null;
  unavailable: number | boolean | null;
  alternative: number | boolean | null;
}


export const list = filteredGetBuilder<CartDto>(`backend/carts`);
export const get = async (id: number | string) => elementGet<{ cart: CartDto, cartItems: CartItemDto[] }>(`backend/carts/${id}`);

export default {
  list,
  get,
};