
import { elementDelete, elementGet, elementPost, filteredGetBuilder } from './response';

export interface BrandDto {
  id: number,
  created: Date | string,
  description: string | null,
  logo: boolean | 1 | 0,
  modified: Date | string | null,
  name: string,
  slug: string,
  active: boolean | 1 | 0,
}

export const list = filteredGetBuilder<BrandDto>(`backend/brands`);
export const add = (data: Partial<BrandDto>) => elementPost<{ id: number }>(`backend/brands`, { json: data });
export const update = (id: number | string, data: Partial<BrandDto>) => elementPost<BrandDto>(`backend/brands/${id}`, { json: data });
export const get = async (id: number | string) => elementGet<BrandDto>(`backend/brands/${id}`);
export const remove = (id: number | string) => elementDelete(`backend/brands/${id}`);

export default {
  list,
  add,
  update,
  get,
  remove,
};