import { elementDelete, elementGet, filteredGetBuilder } from "./response";

export enum CouponTypeEnum {
  // TODO
}

export interface CouponDto {
  id: number | string,
  code: string,
  description: string | null,
  type: CouponTypeEnum,
  amount: number | null,
  cur_usage: number | null,
  max_usage: number | null,
  cart_min_amount: number | null,
  expiry_date: string | Date,
  active: 0 | 1 | boolean,
  product_refs: string | null,
  category_slugs: string | null,
  brands: string | null,
  restrictions_min_amount: number | null,
}

export const list = filteredGetBuilder<CouponDto>(`backend/coupons`);
export const get = async (id: number | string) => elementGet<CouponDto>(`backend/coupons/${id}`);
export const remove = (id: number | string) => elementDelete(`backend/coupons/${id}`);

export default {
  list,
  get,
  remove,
};