import { ProductKitDto } from "@/types/product-kit";
import { ContactDto } from "./contacts";
import { MediaDto } from "./medias";
import { elementDelete, elementGet, elementPost, filteredGetBuilder, FilteredList } from "./response";

export enum ProductAvailability {
  temporary = 'RT',
  definitive = 'RD',
}

export enum ProductShippingAvailability {
  notAvailable = 0,
  available = 1,
  shippingOnRequest = 2,
  // productOnRequest = 3,
}

export interface ProductDto {
  id: number;
  doc_id: number;
  item_id: number;
  name: string | null;
  gtin: string | null;
  quantity: number | null;
  price: number | null;
  tax: number | null;
  total: number | null;
  label: string | null;
  to_doc_id: number | null;
  supplier_id: number | null;
  created: string | Date | null;
  modified: string | Date | null;
  from_doc_line_id: number | null;
  eco_part: number | null;
  total_eco_part: number | null;
  display_order: number | null;
  supplier_name: string | null;
  line_supplier_id: number | null;
  line_supplier_name: string | null;
  image: string | null;
  price_sell: number | null,
  price_buy: number | null;
  price_supplier: number | null,
  slug: string | null;
  reference: string | null;
  reference_slug: string | null;
  brand_item_reference: string | null;
  parent_id: number | null;
  product_eco_part: null,
  is_kit: boolean | number | null;
  packaging_type: string | null;
  packaging_quantity: number | null;
  packaging_unit: string | null;
  degressive_shipping_costs: number | null;
  availability: ProductAvailability | null;
  active: boolean;
  vat_rate: number | null;
  description: string | null;
  vote: number | null;
  vote_count: number | null;
  vote_product_id: number | null;
  meta_title: string | null;
  meta_keywords: string | null;
  meta_description: string | null;
  image_flux: string | null;
  price_sell_modified: string | Date | null;
  price_buy_modified: string | Date | null;
  eco_part_modified: string | Date | null;
  auto_margin: number | null;
  category_id: number | null;
  price_state: number | PriceStateEnum,
}

type ProductDtoAugmented = ProductDto & {
  editor_id?: number;
  editor?: ContactDto;
  medias?: MediaDto[];
};

export enum PriceStateEnum {
  contactUs = 'NC',
  shortage = 'PEN',
}

export type ProductUpdateDto = ProductDto & {
  force_price_buy_modified?: boolean,
  force_price_sell_modified?: boolean,
  force_eco_part_modified?: boolean,
  editor_id?: number,
};

export interface ProductOptionDto {
  id: number,
  option_id: number,
  option_name: string,
  option_value: string | null
}

export interface ProductRefCollisionDto {
  reference: string;
  id: number;
  name: string;
  image: string;
  collision_id: number;
  collision_name: string;
  collision_image: string;
}

export interface ProductFamilyDto {
  self: ProductDto;
  family: ProductDto[];
}

export const list = filteredGetBuilder<ProductDto>(`backend/items`);
export const get = async (productId: number | string) => elementGet<ProductDtoAugmented>(`backend/products/${productId}`);
export const update = async (productId: number | string, product: Partial<ProductUpdateDto>) => elementPost<ProductDto>(`backend/products/${productId}`, { json: product });
export const remove = async (productId: number | string) => elementDelete<void>(`backend/products/${productId}`);

export const kit = async (productId: number | string) => elementGet<FilteredList<ProductKitDto> & { recursive_count: number, recursive_data: (Partial<ProductDto> & ProductKitDto)[] }>(`backend/products/${productId}/kit`);
export const options = (productId: number | string) => filteredGetBuilder<ProductOptionDto>(`backend/products/${productId}/options`);
export const family = async (productId: number | string) => elementGet<ProductFamilyDto>(`backend/products/${productId}/family`);

export const refCollisions = filteredGetBuilder<ProductRefCollisionDto>(`backend/products/ref-collisions`);

export default {
  list,
  get,
  update,
  remove,
  kit,
  options,
  refCollisions,
  family,
}