import { PageState } from '@/store/page-state';
import { DocTypeEnum } from '@/types/doc';
import { stringify } from 'qs';
import useSWRV, { IConfig } from 'swrv';
import { Ref } from 'vue';
import { apiPdg } from './api-pdg';
import { ContactMetaKeyEnum } from './api-pdg/contact-meta';
import { ContactDto } from './api-pdg/contacts';

function generateKey(pageState: Ref<PageState | undefined>) {
  return stringify(pageState.value, {
    sort: (a, b) => a.localeCompare(b),
    skipNulls: true,
  });
}

export function useFiles(folder: string | number, key: string | number) {
  return useSWRV(`files/${folder}/${key}`, () => apiPdg.files.list(folder, key));
}

export function useMedias(pageState: Ref<PageState>) {
  return useSWRV(() => !pageState.value ? undefined : `medias/${JSON.stringify(pageState.value)}`, () => apiPdg.medias.list(pageState.value.filters, pageState.value.options));
}

export function useMedia(id: number | string) {
  return useSWRV(() => `medias/${id}`, () => apiPdg.medias.get(id));
}

export function useJournals(pageState: Ref<PageState>) {
  return useSWRV(() => !pageState.value ? undefined : `journals/${JSON.stringify(pageState.value)}`, () => apiPdg.journals.list(pageState.value.filters, pageState.value.options));
}

export function useRequestQuote(id: number, config?: IConfig) {
  return useSWRV(() => `requestquotes/${id}`, () => apiPdg.requestQuotes.get(id), config);
}

export function useRequestQuoteShipping(id: number, config?: IConfig) {
  return useSWRV(() => `requestquotes/${id}/shipping`, () => apiPdg.requestQuotes.shipping(id), config);
}

export function useRequestQuotes(pageState: Ref<PageState>, config?: IConfig) {
  return useSWRV(() => !pageState.value ? undefined : `requestquotes/${JSON.stringify(pageState.value)}`, () => apiPdg.requestQuotes.list(pageState.value.filters, pageState.value.options), config);
}

export function useContacts(pageState: Ref<PageState>) {
  // const fetcher = debounce((filters, options) => apiPdg.contacts(filters, options), 500);
  return useSWRV(() => !pageState.value ? undefined : `contacts/${JSON.stringify(pageState.value)}`, () => apiPdg.contacts.list(pageState.value.filters, pageState.value.options));
}

export function useContact(id: Ref<number | undefined>, config?: IConfig) {
  return useSWRV(() => id.value ? `contacts/${id.value}` : undefined, () => apiPdg.contacts.get(id.value!), config);
}

export function useContactSalesRep() {
  return useSWRV(`contacts/salesRep`, () => apiPdg.contacts.salesRep(), { dedupingInterval: 60000 });
}

export function useContactDispute(contactId: number) {
  return useSWRV(() => `contactmetas/${contactId}/dispute`, () => apiPdg.contactMetas.getForContact(contactId, ContactMetaKeyEnum.DISPUTE));
}

export function useContactMetaKeys() {
  return useSWRV(() => `contactmetas/keys`, () => apiPdg.contactMetas.keys());
}

export function useContactMetaForKey(contactId: Ref<number | null | undefined>, key: string) {
  return useSWRV(() => !contactId.value || !key ? undefined : `contact/${contactId.value}/metas/${key}`, () => apiPdg.contactMetas.getForContact(contactId.value!, key));
}

export function useProduct(productId: Ref<number | string>) {
  return useSWRV(() => `product/${productId.value}`, () => apiPdg.products.get(productId.value));
}

export function useProductKit(productId: number) {
  return useSWRV(() => `products/${productId}/kit`, () => apiPdg.products.kit(productId));
}

export function useProductFamily(productId: number, config?: IConfig) {
  return useSWRV(() => `products/${productId}/family`, () => apiPdg.products.family(productId), config);
}

export function useProductOptions(productId: number, pageState: Ref<PageState | undefined>) {
  return useSWRV(() => `products/${productId}/options?state=${generateKey(pageState)}`, () => apiPdg.products.options(productId)(pageState.value!.filters, pageState.value!.options));
}

export function useProducts(pageState: Ref<PageState | undefined>, q: { categories: boolean } = { categories: false }) {
  return useSWRV(
    () => !pageState.value ? undefined : `products/${generateKey(pageState)}?${JSON.stringify(q ?? {})}`,
    () => apiPdg.products.list(pageState.value!.filters, pageState.value!.options, q),
    { revalidateOnFocus: false });
}
export function useProductRefCollisions(pageState: Ref<PageState | undefined>) {
  return useSWRV(
    () => !pageState.value ? undefined : `products/ref-collisions/${generateKey(pageState)}`,
    () => apiPdg.products.refCollisions(pageState.value!.filters, pageState.value!.options));
}

export function useProductReviews(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `product-reviews/${JSON.stringify(pageState.value)}`, () => apiPdg.productReviews.list(pageState.value!.filters, pageState.value!.options));
}

export function useSiteReviews(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `site-reviews/${JSON.stringify(pageState.value)}`, () => apiPdg.siteReviews.list(pageState.value!.filters, pageState.value!.options));
}

export function useSiteReview(id: Ref<number | undefined>) {
  return useSWRV(() => !id.value ? undefined : `site-review/${id.value}`, () => apiPdg.siteReviews.get(id.value!));
}

export function useTransactionsVatReport(yearMonth: Ref<string>) {
  return useSWRV(() => yearMonth.value && `transaction/vat-report/${yearMonth.value}`, () => apiPdg.transactions.vatReport(yearMonth.value));
}

export function useTasks(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `tasks/${JSON.stringify(pageState.value)}`, () => apiPdg.tasks.list(pageState.value!.filters, pageState.value!.options));
}

export function useMeTasksCounter(user: Ref<ContactDto | undefined>) {
  return useSWRV(() => !user.value ? undefined : `me/tasks-counter`, () => apiPdg.me.tasksCounter());
}

export function useShipments(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `shipments/${JSON.stringify(pageState.value)}`, () => apiPdg.shipments.list(pageState.value!.filters, pageState.value!.options));
}

export function useShipmentTracking(carrier: string, shipmentNumber: string) {
  return useSWRV(`shipments/tracking/${carrier}/${shipmentNumber}`, () => apiPdg.shipments.tracking(carrier, shipmentNumber), { dedupingInterval: 60000 * 5 });
}

export function useDocs(pageState: Ref<PageState | undefined>, type: DocTypeEnum) {
  return useSWRV(() => !pageState.value ? undefined : `docs/${type}/${JSON.stringify(pageState.value)}`, () => apiPdg.docs.list(pageState.value!.filters, pageState.value!.options, { type }));
}

export function useDocHistories(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `doc-histories/${JSON.stringify(pageState.value)}`, () => apiPdg.docHistories.list(pageState.value!.filters, pageState.value!.options));
}

export function useConditionnements(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `conditionnements/${JSON.stringify(pageState.value)}`, () => apiPdg.conditionnements.list(pageState.value!.filters, pageState.value!.options));
}

export function useConditionnementUnits(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `conditionnementunits/${JSON.stringify(pageState.value)}`, () => apiPdg.conditionnementUnits.list(pageState.value!.filters, pageState.value!.options));
}

export function useVatRates(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `vat-rates/${JSON.stringify(pageState.value)}`, () => apiPdg.vatRates.list(pageState.value!.filters, pageState.value!.options));
}

export function useNewsletterSubscriptionsForContact(contactId: Ref<number | null | undefined>) {
  const { data, error, isValidating, mutate } = useSWRV(() => !contactId.value ? undefined : `newsletters/${contactId.value}`, async () => {
    const contactDto = await apiPdg.contacts.get(contactId.value!);
    if (!contactDto.user) {
      return undefined;
    }
    return (await apiPdg.newsletterSubscriptions.listForEmail(contactDto.user.username!))?.data?.find(item => item.email === contactDto.user.username!);
  });

  return {
    data,
    isValidating,
    error,
    mutate,
  }
}

export function usePurchaseDue(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `purchase/due/${JSON.stringify(pageState.value)}`, () => apiPdg.docs.listPurchaseDue(pageState.value!.filters, pageState.value!.options));
}

export function useSalesStats() {
  return useSWRV(`sales/stats`, apiPdg.salesStats.get);
}

export function useParams(pageState: Ref<PageState | undefined>, displayOutput: boolean = false) {
  return useSWRV(
    () => !pageState.value ? undefined : `params/${JSON.stringify(pageState.value)}?displayOuput=${displayOutput}`,
    () => apiPdg.params.list(pageState.value!.filters, pageState.value!.options, { output: displayOutput ? 'select' : '' }),
    { revalidateOnFocus: false });
}

export function useAccounts(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `accounts/${JSON.stringify(pageState.value)}`, () => apiPdg.accounts.list(pageState.value!.filters, pageState.value!.options));
}

export function useAccount(id: Ref<number | undefined>) {
  return useSWRV(() => !id.value ? undefined : `accounts/${id.value}`, () => apiPdg.accounts.get(id.value!));
}

export function useCategorie(id: Ref<number | undefined>) {
  return useSWRV(() => !id.value ? undefined : `categories/${id.value}`, () => apiPdg.categories.get(id.value!));
}

export function useCategories(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `categories/${JSON.stringify(pageState.value)}`, () => apiPdg.categories.list(pageState.value!.filters, pageState.value!.options));
}

export function useCategorieOptions(categoryId: Ref<number | undefined>, productSheet: boolean) {
  return useSWRV(() => !categoryId.value ? undefined : `categories/${categoryId.value}/options?product_sheet=${productSheet}`, () => apiPdg.categories.options.list(categoryId.value!, productSheet));
}

export function usePayments(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `payments/${JSON.stringify(pageState.value)}`, () => apiPdg.payments.list(pageState.value!.filters, pageState.value!.options));
}

export function useCurrencies(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `currencies/${JSON.stringify(pageState.value)}`, () => apiPdg.currencies.list(pageState.value!.filters, pageState.value!.options));
}

export function useBusinessActivities(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `business-activities/${JSON.stringify(pageState.value)}`, () => apiPdg.businessActivities.list(pageState.value!.filters, pageState.value!.options));
}

export function useCountries(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `countries/${JSON.stringify(pageState.value)}`, () => apiPdg.countries.list(pageState.value!.filters, pageState.value!.options));
}

export function useOrganizationTypes(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `organization-types/${JSON.stringify(pageState.value)}`, () => apiPdg.organizationTypes.list(pageState.value!.filters, pageState.value!.options));
}

export function useLegalForContact(contactId: Ref<number | undefined>) {
  return useSWRV(() => !contactId.value ? undefined : `legals/contact-${contactId.value}`, () => apiPdg.legals.contactLegal(contactId.value!));
}

export function useMatrices(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `matrices/${JSON.stringify(pageState.value)}`, () => apiPdg.matrices.list(pageState.value!.filters, pageState.value!.options));
}

export function useContactHistories(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `contact-histories/${JSON.stringify(pageState.value)}`, () => apiPdg.contactHistories.list(pageState.value!.filters, pageState.value!.options));
}

export function useUnit(unitId: Ref<number | undefined>) {
  return useSWRV(() => !unitId.value ? undefined : `units/${unitId.value}`, () => apiPdg.units.get(unitId.value!));
}

export function useUnits(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `units/${JSON.stringify(pageState.value)}`, () => apiPdg.units.list(pageState.value!.filters, pageState.value!.options));
}

export function useOption(optionId: Ref<number | undefined>) {
  return useSWRV(() => !optionId.value ? undefined : `options/${optionId.value}`, () => apiPdg.options.get(optionId.value!));
}

export function useOptions(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `options/${JSON.stringify(pageState.value)}`, () => apiPdg.options.list(pageState.value!.filters, pageState.value!.options));
}

export function useEmailTemplates(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `emailtemplates/${JSON.stringify(pageState.value)}`, () => apiPdg.emailtemplates.list(pageState.value!.filters, pageState.value!.options));
}

export function useEmailTemplate(emailTemplateId: Ref<number | undefined>) {
  return useSWRV(() => !emailTemplateId.value ? undefined : `emailtemplates/${emailTemplateId.value}`, () => apiPdg.emailtemplates.get(emailTemplateId.value!));
}

export function useEmailTemplateTypes() {
  return useSWRV(`emailtemplates/types`, () => apiPdg.emailtemplates.types());
}

export function useBrand(id: Ref<number | undefined>) {
  return useSWRV(() => !id.value ? undefined : `brands/${id.value}`, () => apiPdg.brands.get(id.value!));
}

export function useBrands(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `brands/${JSON.stringify(pageState.value)}`, () => apiPdg.brands.list(pageState.value!.filters, pageState.value!.options));
}

export function useAddresses(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `addresses/${JSON.stringify(pageState.value)}`, () => apiPdg.addresses.list(pageState.value!.filters, pageState.value!.options));
}

export function useReviews(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `reviews/${JSON.stringify(pageState.value)}`, () => apiPdg.siteReviews.list(pageState.value!.filters, pageState.value!.options));
}

export function useContactEmailsForContact(contactId: Ref<number | undefined>, type?: number) {
  return useSWRV(() => !contactId.value ? undefined : `contacts/emails/${contactId.value}?type=${type ?? '*'})`, () => apiPdg.contactEmails.listForContact(contactId.value!, { type }));
}

export function useContactPhonesForContact(contactId: Ref<number | undefined>, type?: number) {
  return useSWRV(() => !contactId.value ? undefined : `contacts/phones/${contactId.value}?type=${type ?? '*'})`, () => apiPdg.contactPhones.listForContact(contactId.value!, { type }));
}

export function useContactOrgs(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `contactorgs/${JSON.stringify(pageState.value)}`, () => apiPdg.contactOrgs.list(pageState.value!.filters, pageState.value!.options));
}

export function useContactOrgsForOrg(orgId: Ref<number | undefined>) {
  return useSWRV(() => !orgId.value ? undefined : `contactorgs/${orgId.value}`, () => apiPdg.contactOrgs.listForOrg(orgId.value!));
}

export function useProductCategories(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `product-categories/${JSON.stringify(pageState.value)}`, () => apiPdg.productCategories.list(pageState.value!.filters, pageState.value!.options));
}

export function useFaqEntries(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `faq-entries/${JSON.stringify(pageState.value)}`, () => apiPdg.faqEntries.list(pageState.value!.filters, pageState.value!.options));
}

export function useFaqEntry(id: Ref<number | undefined>) {
  return useSWRV(() => !id.value ? undefined : `faq-entries/${id.value}`, () => apiPdg.faqEntries.get(id.value!));
}

export function useCarts(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `cart/${JSON.stringify(pageState.value)}`, () => apiPdg.carts.list(pageState.value!.filters, pageState.value!.options));
}

export function useCart(id: Ref<number | undefined>) {
  return useSWRV(() => !id.value ? undefined : `carts/${id.value}`, () => apiPdg.carts.get(id.value!));
}

export function useCoupons(pageState: Ref<PageState | undefined>) {
  return useSWRV(() => !pageState.value ? undefined : `coupons/${JSON.stringify(pageState.value)}`, () => apiPdg.coupons.list(pageState.value!.filters, pageState.value!.options));
}

export function useCoupon(id: Ref<number | undefined>) {
  return useSWRV(() => !id.value ? undefined : `coupons/${id.value}`, () => apiPdg.coupons.get(id.value!));
}

export function useRequestQuoteStats(fromDate: Ref<string | undefined>, toDate: Ref<string | undefined>) {
  return useSWRV(() => !fromDate.value || !toDate.value ? undefined : `request-quotes/stats/dateFrom=${fromDate.value}&dateTo=${toDate.value}`, () => apiPdg.requestQuotes.stats(fromDate.value!, toDate.value!));
}