
import { apiHttp } from '../api-http';
import { elementGet, filteredGetBuilder } from './response';

export interface OptionDto {
  id: number;
  name: string;
  unit_id: number | null;
  unit_symbol: string | null;
}

export const list = filteredGetBuilder<OptionDto>(`backend/options`);
export const get = (id: number | string) => elementGet<OptionDto>(`backend/options/${id}`);
export const remove = (id: number | string) => apiHttp.delete(`backend/options/${id}`);

export default {
  list,
  get,
  remove,
}
