import { FaqEntryDto } from "@/types/faq-entry";
import { elementDelete, elementGet, elementPost, filteredGetBuilder } from "./response";

export const list = filteredGetBuilder<FaqEntryDto>(`backend/faqentries`);
export const add = (data: Partial<FaqEntryDto>) => elementPost<{ id: number }>(`backend/faqentries`, { json: data });
export const update = (id: number | string, data: Partial<FaqEntryDto>) => elementPost<{ id: number }>(`backend/faqentries/${id}`, { json: data });
export const get = async (id: number | string) => elementGet<FaqEntryDto>(`backend/faqentries/${id}`);
export const remove = async (id: number | string) => elementDelete<never>(`backend/faqentries/${id}`);

export default {
  list,
  add,
  update,
  get,
  remove,
}