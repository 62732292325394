
import { elementDelete, elementGet, elementPost, filteredGetBuilder } from './response';

export const EmailTemplateTypeEnum = {
  CUSTOMER: 1,
  SUPPLIER: 2,
  PAYMENT: 16,
  // TODO : Deprecated
  // A été utilisé pour ajouter le devis PDF en PJ; utiliser `EmailTemplateAttachments.SALE_REQUEST_QUOTE` à la place
  QUOTE: 32,
  ORDER: 64,
  TRACKING: 128,
  INVOICE: 256,
  PRODUCT_REVIEW: 512,
  REQUEST: 1024,
  ABANDONED_CART: 2048,
  // TODO : Deprecated
  // A été utilisé pour ajouter le devis PDF en PJ; utiliser `EmailTemplateAttachments.SHIPPING_SLIP` à la place
  SHIPPING_SLIP: 4096,
  FAQ: 8192,
};

export interface EmailTemplateDto {
  id: number;
  automatic?: boolean;
  type: number; // TODO EmailTemplateTypeEnum;
  name: string;
  subject: string;
  body: string;
  body_json: string | null;
  has_body_json?: boolean;
  delay?: number;
  hooks?: string;
  input_action?: string;
  attachments?: string; // EmailTemplateAttachments.join(',')
  editable_fields: string
}

export interface EmailTemplateEditableFieldDto {
  name: string,
  label: string,
  type: 'text' | 'varchar' | 'html',
}

export enum EmailTemplateAttachments {
  SALE_REQUEST_QUOTE = 'SALE_REQUEST_QUOTE',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  PURCHASE_DELIVERY_NOTE = 'PURCHASE_DELIVERY_NOTE',
  SHIPPING_SLIP = 'SHIPPING_SLIP',
}

export interface EmailTemplateRenderDto {
  subject: string;
  body: string;
}

export type EmailTemplateContextRequestDto = Record<string, string | number>; // TODO
export type EmailTemplateContextDto = Record<string, any | any[]>;

export const render = async (templateId: string | number, data: EmailTemplateContextDto) => elementPost<EmailTemplateRenderDto>(`backend/emailtemplates/${templateId}/render`, { json: data });
export const list = filteredGetBuilder<EmailTemplateDto>(`backend/emailtemplates`);
export const get = async (id: number | string) => elementGet<EmailTemplateDto>(`backend/emailtemplates/${id}`);
export const remove = async (id: number | string) => elementDelete<{ deleted: boolean }>(`backend/emailtemplates/${id}`);
export const types = () => elementGet<{ value: number, text: string }[]>(`backend/emailtemplates/types`);
export const context = async (id: number | string, data: EmailTemplateContextRequestDto) => elementPost<EmailTemplateContextDto>(`backend/emailtemplates/${id}/context`, { json: data });

export default {
  list,
  get,
  types,
  remove,
  render,
  context,
}