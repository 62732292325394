import { elementDelete, elementGet, elementPost, filteredGetEnhancedBuilder } from "./response";

export interface ProductReviewDto {
  id?: number;
  contact_id?: number;
  product_id?: number;
  review?: string;
  vote?: number;
  status?: number | boolean;
  created?: string | Date;
  aim?: number | null;
  response?: string | null;
  title?: string;
  contact_name?: string;
  product_name?: string;
  product_image?: string;
}

export enum ProductReviewAim {
  repair = 1,
  new = 2,
  renovation = 3,
  selfConstruction = 4,
}

export const list = filteredGetEnhancedBuilder<ProductReviewDto, { stats: any }>(`backend/reviews`);
export const add = (data: Partial<ProductReviewDto>) => elementPost<{ id: number }>(`backend/reviews`, { json: data });
export const update = (id: number | string, data: Partial<ProductReviewDto>) => elementPost<{ id: number }>(`backend/reviews/${id}`, { json: data });
export const get = async (id: number | string) => elementGet<ProductReviewDto>(`backend/reviews/${id}`);
export const remove = async (id: number | string) => elementDelete<never>(`backend/reviews/${id}`);

export default {
  list,
  add,
  update,
  get,
  remove,
}