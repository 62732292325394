import { elementGet, filteredGetBuilder } from "./response";

export interface SiteReviewDto {
  id?: number;
  user_id?: number;
  order_id?: number;
  nom?: string | null;
  email?: string | null;
  description?: string;
  statut?: number | boolean;
  date?: string | Date;
  user_name?: string;
  title?: string | null;
  rating?: number | null;
}

export const list = filteredGetBuilder<SiteReviewDto>(`backend/sitereviews`);
export const get = async (id: number | string) => elementGet<SiteReviewDto>(`backend/sitereviews/${id}`);

export default {
  list,
  get,
}