<template>
  <v-navigation-drawer app clipped :value="value" @input="$emit('input', $event)">
    <v-list dense flat app>

      <v-list-item :to="i18nRoute({ name: 'dashboard' })" color="primary">
        <v-list-item-icon>
          <v-icon>{{ mdiHomeAnalytics }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Tableau de bord</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-for="(menu, k1) in items">
        <template v-if="!menu.items">
          <v-list-item v-if="!menu.hidden" :key="k1" :to="i18nRoute({ name: menu.name, params: menu.params })" color="primary">
            <v-list-item-icon>
              <v-icon>{{ menu.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ menu.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="menu.divider" :key="menu.title"></v-divider>
        </template>

        <template v-else>
          <v-list-group :key="menu.title" :prepend-icon="menu.icon" color="secondary">
            <template #activator>
              <v-list-item-content>
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="item in menu.items">
              <v-list-item v-if="!item.hidden && item.title" :key="item.title" :to="i18nRoute({ name: item.name, params: item.params })" color="primary">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="item.divider" :key="item.title" inset></v-divider>
            </template>
          </v-list-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { mdiAccountMultiple, mdiBank, mdiBullhorn, mdiCart, mdiChartBar, mdiCog, mdiCube, mdiHomeAnalytics, mdiImage, mdiSearchWeb } from "@mdi/js";
import { i18nRoute } from '../plugins/Translation';

defineProps<{ value: boolean }>();

defineEmits(['input']);

interface MenuItem {
  title?: string;
  icon?: string;
  name?: string;
  hidden?: boolean;
  divider?: boolean;
  items?: MenuItem[];
  params?: Record<string, string>;
}

const items: MenuItem[] = [
  {
    title: "Contacts",
    icon: mdiAccountMultiple,
    name: "contact",
  },
  {
    title: "Achats",
    icon: mdiCart,
    items: [
      { title: "Commandes", name: "doc-purchases-order" },
      { title: "Factures", name: "doc-purchases-invoice" },
      { divider: true },
      { title: "Paiements", name: "payment-purchases" },
    ],
  },
  {
    title: "Ventes",
    icon: mdiChartBar,
    items: [
      { title: "Demandes", name: "requestquote" },
      { title: "Commandes", name: "doc-sales-order" },
      { title: "Factures", name: "doc-sales-invoice" },
      { divider: true },
      { title: "Paiements", name: "payment-sales" },
      { divider: true },
      { title: "Paniers", name: "cart" },
    ],
  },
  {
    title: "Produits",
    icon: mdiCube,
    items: [
      { title: "Produits", name: "item" },
      { title: "Marques", name: "brand" },
      { title: "Catégories", name: "category" },
      { title: "Avis produits", name: "product-review" },
      { title: "FAQ", name: "faq-entry" },
      { divider: true, },
      { title: "Matrices", name: "matrix" },
      { divider: true, },
      { title: "Conflits Références", name: "item-ref-collisions" },
    ],
  },
  {
    title: "Médias",
    name: "media",
    icon: mdiImage,
  },
  {
    title: "Marketing",
    icon: mdiBullhorn,
    items: [
      { title: "Bannières", name: "banner" },
      { title: "Témoignages", name: "sitereview" },
      { title: "Tunnel demandes", name: "request-quote-funnel" },
      { title: "Codes promo", name: "coupon" },
      { title: "Abonnements newsletter", name: "newsletter-subscriptions" },
      { title: "Modèles de mail", name: "emailtemplate" },
      { title: "Flux", name: "marketing-flux" },
    ],
  },
  {
    title: "Comptabilité",
    icon: mdiBank,
    items: [
      { title: "Suivi CA", name: "turnover" },
      { title: "Suivi TVA", name: "vat-report" },
      { title: "Comptes", name: "account" },
      { title: "Journaux", name: "journal" },
      { title: "Transactions", name: "transaction" },
    ],
  },
  {
    title: "Recherche",
    icon: mdiSearchWeb,
    items: [
      { title: "Curations produits", name: "search-engine-overrides", params: { collection: 'product' } },
      { title: "Curations catégories", name: "search-engine-overrides", params: { collection: 'category' } },
      { title: "Synonymes produits", name: "search-engine-synonyms", params: { collection: 'product' } },
      { title: "Synonymes catégories", name: "search-engine-synonyms", params: { collection: 'category' } },
      { title: "Ré-indexation", name: "search-engine-reindex" },
    ],
  },
  {
    title: "Paramètres",
    icon: mdiCog,
    items: [
      { title: "Unités", name: "unit" },
      { title: "Unités de conditonnement", name: "conditionnementunit" },
      { title: "Conditionnement", name: "conditionnement" },
      { title: "Options", name: "option" },
      { title: "Fonctions", name: "job" },
    ],
  },
];
</script>
