
import { elementGet, elementPost, filteredGetBuilder } from './response';

export interface AccountDto {
  id: number,
  editable: 0 | 1 | boolean,
  status: 0 | 1 | boolean,
  label: string | null
  info?: string | null,
}

export const list = filteredGetBuilder<AccountDto>(`backend/accounts`);
export const add = (data: Partial<AccountDto>) => elementPost<{ id: number }>(`backend/accounts`, { json: data });
export const get = async (id: number | string) => elementGet<AccountDto>(`backend/accounts/${id}`);

export default {
  list,
  add,
  get,
};