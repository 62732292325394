import { router } from '@/router';
import { useAuthStore } from '@/store/auth';
import ky from 'ky';
import { ContactDto } from './api-pdg/contacts';

const $apiHttp = ky.extend({
  prefixUrl: window.config.VUE_APP_PDG_API_BASE_URL ?? process.env.VUE_APP_PDG_API_BASE_URL,
  hooks: {
    beforeRequest: [
      (request) => {
        const authStore = useAuthStore();

        console.info(`➡️ [${request.method}] ${request.url} ⏳`);

        // TODO: only use the token for custom endpoints
        if (authStore.token) {
          request.headers.set('Authorization', `Bearer ${authStore.token}`);
        }
      }
    ],
    afterResponse: [
      // Si refresh token, on tente de reprendre les rennes
      async (input, options, response) => {
        const authStore = useAuthStore();
        if (['GET', 'POST', 'DELETE'].includes(input.method) && response.status === 401 && !input.url.match(/\/auth\/refresh$/) && authStore.refreshToken) {
          console.info(`🔄 Auth failed, but refresh token is available`);

          await refreshAccessToken(authStore.refreshToken);

          input.headers.set('Authorization', `Bearer ${authStore.token}`);

          return ky(input, options);
        }
        return response;
      },
      async (request, _options, response) => {
        const authStore = useAuthStore();
        console.info(`⬅️ [${request.method}] ${request.url} [${response.status === 200 ? '✔️' : `❌ ${response.status}`}]`);

        if ([401, 403].includes(response.status)) {
          if (authStore.user) {
            clearAuth();
          }

          await router.push({ name: "signin", query: { returnUrl: router.currentRoute.fullPath, ...router.currentRoute.query /* on prend le premier returnUrl */, disconnected: 'true' } });
        }
        return response;
      }
    ],
  },
});

async function signIn(credentials: { username: string, password: string }) {
  const authStore = useAuthStore();
  const res = await $apiHttp.post("auth/signin", { json: credentials }).json<{ data: { token?: string, refresh_token?: string, errors?: Record<string, string> } | undefined }>();
  if (res.data && res.data.token) {
    authStore.refreshToken = res.data.refresh_token;
    await signInWithToken(res.data.token);
  }
  return res;
}

async function signInWithToken(newToken: string) {
  const authStore = useAuthStore();
  try {
    authStore.token = newToken;
    const response = await $apiHttp.get("user/user").json<{ data: ContactDto }>();
    authStore.user = response.data;
  } catch (e) {
    authStore.token = undefined;
    authStore.refreshToken = undefined;
    authStore.user = undefined;
  }
}

async function refreshAccessToken(refreshToken: string) {
  const authStore = useAuthStore();
  try {
    const response = await $apiHttp.post(`auth/refresh`, { json: { refresh_token: refreshToken } }).json<{ data: { token: string, refresh_token: string } }>();
    authStore.token = response.data.token;
    authStore.refreshToken = response.data.refresh_token;
  } catch (e) {
    authStore.token = undefined;
    authStore.refreshToken = undefined;
    authStore.user = undefined;
    throw e;
  }
}

async function signOut() {
  await $apiHttp.post("auth/signout");
  clearAuth();
}

function clearAuth() {
  const authStore = useAuthStore();
  authStore.token = undefined;
  authStore.refreshToken = undefined;
  authStore.user = undefined;
  authStore.$persist();
}

export const apiHttp = $apiHttp;
export const apiHttpUtils = {
  auth: {
    signIn,
    signInWithToken,
    signOut,
    refreshAccessToken,
  },
};
