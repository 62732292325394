import { PageStateFilters, PageStateOptions } from "@/store/page-state";
import { elementDelete, elementGet, filteredGetEnhancedBuilder } from "./response";

export interface ContactPhoneDto {
  id: number,
  type: number, // bits ContactPhoneDtoType,
  contact_id: number,
  tel: string,
}

export enum ContactPhoneDtoType {
  default = 1,
  office = 2,
  home = 3,
  cellphone = 8,
  fax = 16,
}

export const list = filteredGetEnhancedBuilder<ContactPhoneDto, { typeList: { value: number, text: string } }>(`backend/phones`);
export const get = async (id: number | string) => elementGet<ContactPhoneDto>(`backend/phones/${id}`);
export const remove = (id: number | string) => elementDelete(`backend/phones/${id}`);

export const listForContact = (contactId: number, additionalFilters: PageStateFilters = {}, options: PageStateOptions = { itemsPerPage: 10, page: 1, sortBy: ['tel'], sortDesc: [false] }) => {
  return list({ ...additionalFilters, contact_id: contactId }, options);
}

export default {
  list,
  get,
  remove,
  listForContact,
};