export const API_IMAGE_PATH = `${window.config.VUE_APP_PDG_API_BASE_URL ?? process.env.VUE_APP_PDG_API_BASE_URL}/images/`;

export function imageSrc(image: string | null | undefined) {
  if (!image || typeof image !== "string") {
    return null;
  }
  return API_IMAGE_PATH + 'items/' + image.slice(0, 1) + "/" + image.slice(1, 2) + "/" + image.slice(2, 3) + "/" + image;
}

export function logoSrcBrand(id: number, ext: number, modified: number | string | Date) {
  let fileExt = '';
  if (ext === 1) fileExt = "png";
  else if (ext === 2) fileExt = "svg";

  const m = Math.floor(id / 1000000);
  const k = Math.floor((id - m * 1000000) / 1000);

  if (modified === undefined) {
    return API_IMAGE_PATH + 'brands' + "/" + m + "/" + k + "/" + id + "." + fileExt;
  }
  const d = new Date(modified).getTime();
  return API_IMAGE_PATH + 'brands' + "/" + m + "/" + k + "/" + id + "." + fileExt + "?t=" + d;
}

export function logoSrcContact(id: number, ext = undefined, modified = undefined) {
  let fileExt = '';
  if (ext === undefined) fileExt = "png";
  else if (ext === 1) fileExt = "png";
  else if (ext === 2) fileExt = "svg";

  const m = Math.floor(id / 1000000);
  const k = Math.floor((id - m * 1000000) / 1000);

  if (modified === undefined) {
    return API_IMAGE_PATH + 'contacts' + "/" + m + "/" + k + "/" + id + "." + fileExt;
  }
  const d = new Date(modified).getTime();
  return API_IMAGE_PATH + 'contacts' + "/" + m + "/" + k + "/" + id + "." + fileExt + "?t=" + d;
}
