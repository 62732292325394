import { PageStateFilters, PageStateOptions } from "@/store/page-state";
import { elementGet, filteredGetEnhancedBuilder } from "./response";
import { apiHttp } from "../api-http";

export interface ContactEmailDto {
  id: number,
  type: number,
  contact_id: number,
  label: string,
}

export const list = filteredGetEnhancedBuilder<ContactEmailDto, { typeList: { value: number, text: string } }>(`backend/emails`);
export const get = async (id: number | string) => elementGet<ContactEmailDto>(`backend/emails/${id}`);
export const remove = (id: number | string) => apiHttp.delete(`backend/emails/${id}`);

export const listForContact = (contactId: number, additionalFilters: PageStateFilters = {}, options: PageStateOptions = { itemsPerPage: 10, page: 1, sortBy: ['label'], sortDesc: [false] }) => {
  return list({ ...additionalFilters, contact_id: contactId }, options);
}

export default {
  list,
  get,
  remove,
  listForContact,
};