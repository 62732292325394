import { elementDelete, elementGet, elementPost, filteredGetBuilder } from "./response";

export interface ProductCategoryDto {
  id: number,
  product_id: number,
  category_id: number,
  order: number,
  category_name: string,
  product_name: string,
}

export const list = filteredGetBuilder<ProductCategoryDto>(`backend/productcategories`);
export const add = (data: Partial<ProductCategoryDto>) => elementPost<{ id: number }>(`backend/productcategories`, { json: data });
export const get = async (id: number | string) => elementGet<ProductCategoryDto>(`backend/productcategories/${id}`);
export const remove = async (id: number | string) => elementDelete<never>(`backend/productcategories/${id}`);

export default {
  list,
  add,
  get,
  remove,
}